import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import Img from 'gatsby-image';
import { formatReadingTime } from '../utils/helpers';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article
              key={node.fields.slug}
              style={{
                marginBottom: rhythm(3)
              }}
            >
              <header>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <Img
                    className="image-thumbnail-index"
                    sizes={node.frontmatter.thumbnail.childImageSharp.sizes}
                    style={{
                      marginBottom: rhythm(3 / 4),
                      borderRadius: `1rem`,
                      boxShadow: `0 2px 4px 0 rgba(17,51,83,.14)`,
                    }}
                  />
                </Link>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                    marginTop: rhythm(2 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <div
                  className="muted"
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                <small>
                    {node.frontmatter.date} {` • ${formatReadingTime(node.timeToRead)}`}
                  </small>
                </div>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
